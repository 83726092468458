import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import { post, get } from 'helpers/api_helper';
import { INVOICE_ALL_URL } from 'helpers/url_helper';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Col,
    Container,
    Modal,
    Row,
    Card,
    CardBody
} from "reactstrap";
import { NumberToWordsConverter, customFormatDate, teethsTable } from 'helpers/basic_helper';
import html2pdf from 'html2pdf.js';
import logo from '../../assets/images/241002_print.png'

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const AllInvoices = () => {
    const query = useQuery();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [PDFloading, setPDFLoading] = useState(false);

    const pdfRef = useRef();

    const handleDownload = () => {
        setPDFLoading(true); // Show loading screen
        const element = pdfRef.current;

        const options = {
            margin: 0.2,
            filename: 'document.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
        };

        html2pdf()
            .from(element)
            .set(options)
            .save()
            .then(() => {
                setPDFLoading(false); // Hide loading screen after generation
            })
            .catch((error) => {
                console.error('PDF generation error:', error);
                setPDFLoading(false); // Hide loading screen on error
            });
    };

    console.log(PDFloading)

    const getData = async () => {
        try {
            const res = await get(`${INVOICE_ALL_URL}?type=${query.get('type')}`);
            if (res?.status === 200) {
                setData(res.response);
            } else {
                throw new Error('Failed to fetch invoices');
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, [query.get('type')]);  // Ensure it updates when query parameter changes.

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    
    
    // Generate the rows with group names (doctor names) before each group's rows
    const products = (OPData, data) => {
        let req_by = [];
        let total_units = 0;
        let total_amount = 0;
        let tabledata = '';
        
        OPData?.orders?.forEach((pv) => {
            if (!req_by[pv.doctor]) {
                req_by[pv.doctor] = [];
            }
            req_by[pv.doctor].push(pv);
        });
        
        Object.keys(req_by).map((doctorName, i) => {
            let serialNumber = 1
            const groupRows = req_by[doctorName].map((pv, j) => {
                const total = parseFloat(pv.product_unit) * parseFloat(pv.product_price);
                total_units += parseFloat(pv.product_unit);
                total_amount += total;
                const rows = (
                    <tr key={`op_${i}_${j}`}>
                        <td className="p-0 ps-1" style={{fontSize: '10px'}}>{serialNumber}</td>
                        <td className="p-0 ps-1" style={{fontSize: '10px'}}>{pv.order_number}</td>
                        <td className="p-0 ps-1" style={{fontSize: '10px'}}>{pv.orderform_number}</td>
                        <td className="p-0 ps-1" style={{fontSize: '10px'}}>{customFormatDate(pv.orderform_date, 'DD/MM/YYYY')}</td>
                        <td className="p-0 ps-1" style={{fontSize: '10px'}}>{pv.patient_name}</td>
                        <td className="p-0 ps-1" style={{fontSize: '10px'}}>{pv.product}</td>
                        <td className="p-0" style={{padding: '0px', width: 'auto', verticalAlign: 'middle'}}>{teethsTable(JSON.parse(pv.teeth))}</td>
                        <td className="p-0 ps-1 text-center">{pv.product_unit}</td>
                        <td className="p-0 ps-1" style={{fontSize: '10px'}}>{parseFloat(pv.product_price).toFixed(2)}</td>
                        <td className="p-0 ps-1 pe-1" style={{textAlign: 'right', fontSize: '10px'}}>{total.toFixed(2)}</td>
                    </tr>
                );
    
                serialNumber++;
                return rows;
            });
    
            // Return the group name row followed by the group rows
            tabledata =(
                <React.Fragment key={`group_${i}`}>
                    <tr>
                        <td colSpan="11" style={{fontSize: '10px', fontWeight: 'bold'}}> {doctorName ? `Requested By Dr. ${doctorName}`: '-' }</td>
                    </tr>
                    {groupRows}
                </React.Fragment>
            );
        });

        return <tbody>
            {tabledata}
            <tr style={{fontWeight: '700'}}>
                <td colSpan={7} style={{textAlign: 'right'}}>Sub Total: </td>
                <td>{total_amount}</td>
                <td colSpan={2} style={{textAlign: 'right'}}>{total_amount.toFixed(2)}</td>
            </tr>

            <tr rowSpan={2}>
                <td colSpan={5}>
                    <b>GSTIN : {data?.org?.gst}</b><br />
                    <b>HSN Number: {data?.org.hsn}</b><br />
                    <b>State : {data?.org.state} State Code: {data?.org.state_code}</b>
                </td>
                <td colSpan={5}>
                    {getGstValue(OPData)}
                    <div className="row" style={{marginTop: '6px', marginBottom: '0px'}}>
                        <div className="col-sm-7" style={{textAlign: 'right', fontWeight: '700'}}>
                            Grand Total
                        </div>
                        <div className="col-sm-5" style={{textAlign: 'right', fontWeight: '700'}}>
                        { new Intl.NumberFormat('en-US', { style: 'currency', currency: 'INR' }).format(getGrandTotal(OPData, total_amount))}
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colSpan={12}><strong>Grand Total (₹): <NumberToWordsConverter number={getGrandTotal(OPData, total_amount)} />.</strong></td>
            </tr>
        </tbody>

    }

    const getGstValue = (data) => {
        const { invoice } = data
        const gstValue = parseFloat(invoice.gst);
        const isPanIndia = data.invoice.is_panindia > 0;
        const total_amount = invoice?.invoice_total;
    
        if (isPanIndia) {
            return (
                <div className="row" style={{ borderBottom: '1px solid #000', margin: '1px -5px'}}>
                    <div className="col-sm-7" style={{ textAlign: 'right' }}>
                        IGST @ {gstValue.toFixed(2)} %
                    </div>
                    <div className="col-sm-5" style={{ textAlign: 'right' }}>
                        {((total_amount * gstValue) / 100).toFixed(2)}
                    </div>
                </div>
            );
        } else {
            const cgstValue = (gstValue / 2).toFixed(2);
            return (
                <>
                    <div className="row" style={{ borderBottom: '1px solid #000', margin: '2px -5px' }}>
                        <div className="col-sm-7" style={{ textAlign: 'right' }}>
                            CGST @ {cgstValue} %
                        </div>
                        <div className="col-sm-5" style={{ textAlign: 'right' }}>
                            {((total_amount * (gstValue / 2)) / 100).toFixed(2)}
                        </div>
                    </div>
                    <div className="row" style={{ borderBottom: '1px solid #000', margin: '4px -5px' }}>
                        <div className="col-sm-7" style={{ textAlign: 'right' }}>
                            SGST @ {cgstValue} %
                        </div>
                        <div className="col-sm-5" style={{ textAlign: 'right' }}>
                            {((total_amount * (gstValue / 2)) / 100).toFixed(2)}
                        </div>
                    </div>
                </>
            );
        }
    };

    const getGrandTotal = (data, total_amount) => {
        const { invoice } = data;
        const gstAmount = (parseFloat(total_amount) * parseFloat(invoice.gst)) / 100
        return parseFloat(total_amount) + gstAmount;
    }

    return (
        <React.Fragment>
            <div style={{backgroundColor: '#fff'}}>
                <MetaTags> <title>Orderform | KS-Lab</title> </MetaTags>
                {
                    data !== null ?
                    <>
                        {/* <div style={{width: '100%', textAlign:'center'}}>
                            <button onClick={() => handleDownload()} className='btn btn-primary' type="button" style={{margin: '26px auto', width: '120px', height: '60px'}}><i className='fas fa-print me-2'></i>Print</button>
                        </div> */}

                        <div ref={pdfRef}>
                            {
                                data?.rows.map((v, i) => {
                                    return <div className="all-invoices" key={`AILB_${i+1}`} style={{ pageBreakAfter: 'always', margin:'0 auto', marginBottom: '20px', width: '98%'}}>
                                        <br />
                                        <br />
                                        <Row>
                                            <Col sm={2} >
                                                <img src={logo} style={{width: '92px', marginRight: '16px', float: 'left'}} />
                                            </Col>
                                            <Col sm={5}>
                                                <b>{data?.org?.title}</b>
                                                <address style={{width: '260px', marginBottom: '0'}}>{data?.org?.address}</address>
                                            </Col>
                                            <Col>
                                                <span>phone: {data?.org?.mobile}</span><br />
                                                <span>Email: {data?.org?.email}</span><br />
                                                <span>Web: {data?.org?.website.replace('https://', 'www.')}</span><br />
                                            </Col>
                                        </Row>

                                        {/* Header */}
                                        <div className="row">
                                            <div className='mt-2 mb-0' style={{width: '100%', textAlign:'center', marginTop: '6px'}}>
                                                <h4>{query.get('type')}</h4>
                                            </div>
                                        </div>

                                        {/* Header Data */}
                                        <div style={{display: 'flex'}}>
                                            <div style={{padding: '10px', width: '60%'}}>
                                                <span style={{fontSize: '18px', fontWeight: '700'}}>Dr. {(v?.client?.title)?.toUpperCase()}</span><br />
                                                <span>{v?.client?.address}, {v?.client?.city}, {v?.client?.state} - {v?.client?.pincode}</span><br />
                                                <span>{v?.client?.state} State Code:{v?.client?.state_code}</span><br />
                                            </div>

                                            <div style={{width: '40%', textAlign: 'right'}}>
                                                <strong>Invoice #.</strong> <span>{v?.invoice?.invoice_number}</span><br />
                                                <strong>Date</strong> <span>{v?.invoice?.invoice_date}</span>
                                            </div>
                                        </div>

                                        {/* Body Data */}
                                        <table className="table table-bordered" style={{ borderCollapse: 'collapse', margin: '2px' }}>
                                            <thead>
                                                <tr>
                                                    <th className="p-0 ps-1" >#</th>
                                                    <th className="p-0 ps-1" >Order #</th>
                                                    <th className="p-0 ps-1" >Challan #</th>
                                                    <th className="p-0 ps-1" >Challan Date</th>
                                                    <th className="p-0 ps-1" >Patient</th>
                                                    <th className="p-0 ps-1" >Product</th>
                                                    <th className="p-0 ps-1" >Teeth</th>
                                                    <th className="p-0 ps-1" >Units</th>
                                                    <th className="p-0 ps-1" >Rate/Unit </th>
                                                    <th className="p-0 ps-1" >Total</th>
                                                </tr>
                                            </thead>
                                            
                                            {products(v, data)}
                                        </table>
                                        <br />
                                        <br />
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <span>BANKER NAME:</span><br />
                                                <span>{data?.org?.bank?.bank}{' '}{data?.org?.bank?.branch}</span><br />
                                                <span>RTGS/NEFT IFS Code: {data?.org?.bank?.ifscode}</span><br />
                                                <span>C/A no.{data?.org?.bank?.account} </span>
                                            </div>
                                            <div className="col-sm-6" style={{textAlign: 'right'}}>
                                                <span style={{bottom: '16px'}} >Authorized</span><br />
                                                <img width="120" src={data?.org?.qrlink} />
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </> :
                    <div style={loadingStyle}>
                        <img src="https://dglab.keepsmiles.in/api/uploads/241002/241002_print.png" style={{width: '92px', marginRight: '16px', float: 'left'}} /><br /><br /><br /><br />
                        <span>Loading Data...</span>
                    </div>
                }
            </div>
        </React.Fragment>
    );
};

export default AllInvoices;
