import OrderForm from './form';
import { get } from 'helpers/api_helper';
import { ORDER_URL } from 'helpers/url_helper';
import { useEffect, useState } from 'react';

const OrderEditModule = (order_id) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isEditData, setIsEditData] = useState(false);
    const [EditableObj, setEditableObj] = useState(null);

    useEffect(() => {
        const a = async(data) => {
            // setIsEditLoading(!isEditLoading)
            // setLoaderRowId(data?.id)
            const res = await get(`${ORDER_URL}?order=${order_id}`)
            console.log(res)
            if(res.status == 200){
                setIsEditData(true)
                setEditableObj(res.response)
                setIsOpen(!isOpen)
            }
        }
        if(order_id)
            a(order_id);
    }, [])
    

    return 'asd'
} 

export default OrderEditModule;